$font-prefix: ".." !default;
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"),
    url("#{$font-prefix}/complete/woff2/IBMPlexSans-Text.woff2") format("woff2"),
    url("#{$font-prefix}/complete/woff/IBMPlexSans-Text.woff") format("woff");
}

@import "cyrillic";
@import "pi";
@import "latin3";
@import "latin2";
@import "latin1";
@import "greek";
