// import all of bootstrap
@import "bootstrap/scss/bootstrap";

// Bootstrap overrides for this template
.bg-primary {
  background: $theme-primary;
  background: -webkit-linear-gradient(
    $theme-primary,
    darken($theme-primary, 5%)
  );
  background: linear-gradient($theme-primary, darken($theme-primary, 5%));
}

.text-primary {
  color: $theme-primary;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.btn-outline {
  color: white;
  border: 1px solid;
  border-color: white;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: white;
    border-color: $theme-primary;
    background-color: $theme-primary;
  }
}

.btn {
  border-radius: 300px;
  @include alt-font;
}

.btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

code {
  color: #43e83e !important;
}

.container {
  max-width: 50em;

  .iframe-container {
    margin-top: 3em;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    /* Then style the iframe to fit in the container div with full height and width */
    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
