// Styling for the masthead
header.masthead {
  width: 100%;
  background: url("../img/purple.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5em;

  .header-content {
    padding: 1em;
    background-color: #000000b3;

    color: white;
    h1 {
      font-size: 30px;
    }
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 992px) {
    height: 100vh;
    min-height: 775px;
  }
}
