// Styling for the navbar
.navbar {
  border-color: fade-out($gray-darker, 0.95);
  background-color: white;
  @include transition-all;
  @include heading-font;
  .navbar-brand {
    color: $theme-primary;
    @include heading-font;
    &:hover,
    &:focus {
      color: darken($theme-primary, 10%);
    }
  }
  .nav-item {
    margin-top: 0.2em;
  }
  .navbar-toggler {
    font-size: 12px;
    color: $gray-darker;
  }
  .navbar-nav {
    > li {
      > a {
        font-size: 11px;
        @include alt-font;
        &.active {
          color: $theme-primary !important;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      }
      > a,
      > a:focus {
        color: $gray-darker;
        &:hover {
          color: $theme-primary;
        }
      }
    }
  }
  @media (min-width: 992px) {
    border-color: transparent;
    background-color: transparent;
    .navbar-brand {
      color: fade(white, 70%);
      &:hover,
      &:focus {
        color: white;
      }
    }
    .navbar-nav > li > a,
    .navbar-nav > li > a:focus {
      color: fade-out(white, 0.3);
      &:hover {
        color: white;
      }
    }
    &.navbar-shrink {
      border-color: fade-out($gray-darker, 0.9);
      background-color: white;
      .navbar-brand {
        color: $gray-darker;
        &:hover,
        &:focus {
          color: $theme-primary;
        }
      }
      .navbar-nav > li > a,
      .navbar-nav > li > a:focus {
        color: $gray-darker;
        &:hover {
          color: $theme-primary;
        }
      }
    }
  }
}
